import { PartialModel } from './Model';
import { PartialDirector } from './Director';
import { PartialPhoto } from './Photo';
import { PartialTag } from './Tag';
import { IntBoolean } from '@/services/api';
import { BaseEntity } from './BaseEntity';

export enum VideoStatus {
  DRAFT = 'draft',
  PUTFILE_PENDING = 'putfile_pending',
  PUTFILE_PROCESSING = 'putfile_processing',
  PUTFILE_FAILED = 'putfile_failed',
  PROCESSING = 'processing',
  SCHEDULING = 'scheduling',
  PUBLISHED = 'published',
}

export enum VideoSourceType {
  CLOUDFLARE = 'cloudflare',
  CDN77 = 'cdn77',
}

export interface Video extends BaseEntity {
  serialNumber: string
  title: string
  titleCn: string
  description: string
  descriptionCn: string
  video: string
  videoLink: string
  videoSource: string
  videoSourceLink: string
  trailer: string
  trailerLink: string
  trailerSource: string
  trailerSourceLink: string
  subtitleCn: string
  subtitleCnLink: string
  subtitleEn: string
  subtitleEnLink: string
  videoDuration: number
  views: number
  cover: string
  coverSource: string
  coverLink: string
  previewVideo: string
  previewVideoLink: string
  type: string
  token: number
  publishedAt: string
  status: VideoStatus
  source: VideoSourceType
  originalViews: number
  likersCount: number
  dislikersCount: number
  modelIds: number[]
  models: PartialModel[]
  directors: PartialDirector[]
  tags: PartialTag[]
  photos: PartialPhoto[]
  isFree: IntBoolean
  isMain: IntBoolean
  isSrt: IntBoolean
  tooltips: string
  subscriberTrailerViews: number
  visitorTrailerViews: number
}

export type PartialVideo = Partial<Video>;
