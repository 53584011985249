
import {
  defineComponent,
  ref,
  unref,
  toRefs,
  onMounted,
  watch
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  ElMessage,
  ElForm,
  ElNotification,
  ElMessageBox
} from 'element-plus';
// import { Rules } from 'async-validator';
import { UploadFile } from 'element-plus/es/components/upload/src/upload.type';
import dayjs from 'dayjs';

import ImageUploader, { UploadImageEvent } from '@/components/ImageUploader.vue';
import VideoUploader, { UploadEvent } from '@/components/video-uploader/Index.vue';
import SubtitleUploader, { UploadSubtitleEvent } from '@/components/SubtitleUploader.vue';
import {
  getModels,
  getTags,
  getDirectors,
  uploadVideo,
  MAX_PER_PAGE,
  IntBoolean
} from '@/services/api';
import { useCreateVideo, useUpdateVideo, useVideo } from '@/composables/api';
import { PartialVideo, VideoStatus, VideoSourceType } from '@/interfaces/Video';
import { Model } from '@/interfaces/Model';
import { Tag } from '@/interfaces/Tag';
import { Director } from '@/interfaces/Director';
import useFormErrors from '@/utils/validate';
import { useI18n } from 'vue-i18n';

const DEFAULT_FORM_VALUES: PartialVideo & Partial<{
   directorIds: number[]
   tagIds: number[]
  }> = {
    serialNumber: '',
    videoDuration: 0,
    cover: '',
    coverSource: '',
    coverLink: '',
    previewVideo: '',
    previewVideoLink: '',
    trailer: '',
    trailerLink: '',
    trailerSource: '',
    trailerSourceLink: '',
    source: VideoSourceType.CLOUDFLARE,
    video: '',
    videoLink: '',
    videoSource: '',
    videoSourceLink: '',
    title: '',
    description: '',
    publishedAt: '',
    status: VideoStatus.DRAFT,
    type: '',
    models: [],
    modelIds: [],
    directorIds: [],
    tagIds: [],
    views: 0,
    token: 0,
    isFree: IntBoolean.FALSE,
    isMain: IntBoolean.FALSE,
    isSrt: IntBoolean.FALSE
  };

const rules = {
  serialNumber: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  cover: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  previewVideo: [
    {
      required: true,
      trigger: 'change'
    }
  ],
  trailerSource: [
    {
      required: false,
      trigger: 'change'
    }
  ],
  videoSource: [
    {
      required: true,
      trigger: 'change'
    }
  ],
  titleCn: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  title: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  descriptionCn: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  description: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  publishedAt: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  type: [
    {
      required: true,
      trigger: 'change'
    }
  ],
  modelIds: [
    {
      required: true,
      trigger: 'change'
    }
  ],
  views: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  token: [
    {
      required: true,
      trigger: 'blur'
    }
  ]
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ImageUploader,
    VideoUploader,
    SubtitleUploader
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const videoId = useRoute().params.id as string;
    const page = useRoute().query.page as string;
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { formErrors, bindFormItemError } = useFormErrors();
    const { t } = useI18n();

    const previewFileList = ref([]);
    watch(() => formValues.value.previewVideo, (previewVideo) => {
      previewFileList.value = previewVideo
        ? [{ name: previewVideo }]
        : [];
    });

    // format publish at
    watch(() => formValues.value.publishedAt, (publishedAt) => {
      formValues.value.publishedAt = dayjs(publishedAt).format('YYYY-MM-DD HH:mm:ss');
    });

    const models = ref<Model[]>([]);
    const directors = ref<Director[]>([]);
    const tags = ref<Tag[]>([]);

    const { isLoading: isCreatedLoading, mutate: create } = useCreateVideo();
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdateVideo();

    const fetchModels = async() => {
      models.value = (await getModels({ query: { perPage: MAX_PER_PAGE, sortBy: 'name' } })).data;
    };

    const fetchDirectors = async() => {
      directors.value = (await getDirectors()).data;
    };

    const fetchTags = async() => {
      tags.value = (await getTags({ query: { perPage: MAX_PER_PAGE } })).data;
    };

    const { data, dataUpdatedAt } = useVideo({ videoId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });

    watch(dataUpdatedAt, () => {
      formValues.value = data.value.data;
    });

    onMounted(async() => {
      fetchModels();
      fetchDirectors();
      fetchTags();
    });

    const handleCoverCrop = ({ path }: UploadImageEvent) => {
      formValues.value.cover = path;
    };

    const handleSubtitleCnUpload = ({ path }: UploadSubtitleEvent) => {
      formValues.value.subtitleCn = path;
    };

    const handleSubtitleEnUpload = ({ path }: UploadSubtitleEvent) => {
      formValues.value.subtitleEn = path;
    };

    const handlePreviewVideoRemove = () => {
      formValues.value.previewVideo = '';
    };

    const handlePreviewVideoChange = async({ raw }: UploadFile) => {
      if (raw.size < 5242880) {
        const { data } = await uploadVideo({ data: { video: raw } });

        formValues.value.previewVideo = data.path;
      } else {
        ElMessageBox.confirm(
          'File above 5MB cannot be uploaded',
          'Warning',
          {
            confirmButtonText: 'OK',
            showCancelButton: false,
            type: 'warning'
          }
        );

        previewFileList.value = previewFileList.value.slice(-1);
      }
    };

    const handleTrailerUpload = ({ path }: UploadEvent) => {
      formValues.value.trailerSource = path;
    };

    const handleVideoUpload = ({ path }: UploadEvent) => {
      formValues.value.videoSource = path;
    };

    const handleTrailerError = () => {
      ElNotification({
        title: 'Warning',
        message: 'Failed to upload trailer. Please try again.',
        type: 'warning'
      });
    };

    const handleVideoError = () => {
      ElNotification({
        title: 'Warning',
        message: 'Failed to upload video. Please try again.',
        type: 'warning'
      });
    };

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (valid) {
          if (isEdit.value) {
            update({ videoId, data: formValues.value }, {
              onSuccess() {
                ElMessage.success({
                  message: 'success!',
                  type: 'success'
                });

                router.push({
                  name: 'list-videos'
                });
              },
              onError(error: any) {
                ElMessage.error(error.response?.data.message);
                formErrors.value = error.response?.data.errors;
              }
            });

            return;
          }

          create({ data: formValues.value }, {
            onSuccess() {
              ElMessage('success!');

              formValues.value = DEFAULT_FORM_VALUES;
              router.push({
                name: 'list-videos'
              });
            },
            onError(error: any) {
              ElMessage.error(error.response?.data.message);
              formErrors.value = error.response?.data.errors;
            }
          });
        }
      });
    };

    return {
      formRef,
      formValues,
      previewFileList,
      models,
      directors,
      tags,
      rules,
      IntBoolean,
      page,
      handleCoverCrop,
      handlePreviewVideoChange,
      handlePreviewVideoRemove,
      handleTrailerUpload,
      handleSubtitleCnUpload,
      handleSubtitleEnUpload,
      handleTrailerError,
      handleVideoUpload,
      handleVideoError,
      VideoStatus,
      VideoSourceType,
      isCreatedLoading,
      isUpdatedLoading,
      submitForm,
      formErrors,
      t,
      bindFormItemError
    };
  },
  computed: {
    videoSourceRules() {
      const rules = [];

      if (this.formValues.source === VideoSourceType.CLOUDFLARE) {
        console.log('debug CY - formValues true');
        console.log('debug CY - formValues true', this.formValues.source === VideoSourceType.CLOUDFLARE);
        rules.push({
          required: true,
          message: 'Please upload a video source',
          trigger: 'change'
        });
      } else {
        console.log('debug CY - formValues false');
        rules.push({
          required: false,
          message: 'Please upload a video source',
          trigger: 'change'
        });
      }

      return rules;
    }
  }
  // ,
  // methods: {
  //   handleSourceChange() {
  //     if (this.formValues.source === 'cdn77') {
  //       rules.videoSource[0].required = false;
  //     } else {
  //       rules.videoSource[0].required = true;
  //       console.log(this.formValues.source);
  //     }
  //   }
  // }

});

