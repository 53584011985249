
import { defineComponent, ref, toRefs } from 'vue';
import { Plus, Delete } from '@element-plus/icons';
import { UploadFile } from 'element-plus/es/components/upload/src/upload.type';
import { uploadSubtitleFile, SubtitleResponseData } from '@/services/api';

export type UploadSubtitleEvent = SubtitleResponseData;

export default defineComponent({
  components: {
    Plus,
    Delete
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  emits: ['upload', 'delete'],
  setup(props, { emit }) {
    const { type } = toRefs(props);
    const subtitleFile = ref<File | null>(null);
    const uploadedSubtitle = ref(false);
    const replaceable = ref(true);

    const upload = async() => {
      if (!subtitleFile.value) {
        console.error('没有选择文件');
        return;
      }

      try {
        const response = await uploadSubtitleFile({
          subtitle: subtitleFile.value,
          type: type.value
        });

        emit('upload', response.data);
        uploadedSubtitle.value = true;
      } catch (error) {
        console.error('上传错误:', error);
        uploadedSubtitle.value = false;
      }
    };

    const handleFileChange = ({ raw }: UploadFile) => {
      subtitleFile.value = raw;
      upload();
    };

    const removeSubtitleFile = () => {
      subtitleFile.value = null;
      uploadedSubtitle.value = false;
    };

    return {
      handleFileChange,
      removeSubtitleFile,
      uploadedSubtitle,
      replaceable
    };
  }
});
